import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import App from './App';
import { Login } from './components/Login';
import { Toast } from './components/common';
import { Tools } from './components/tools/Tools';
import { Configuration } from './components/tools/configuration/Configuration';
import { ConfigurationDetail } from './components/tools/configuration/ConfigurationDetail';
import { Monitor } from './components/tools/monitor/Monitor';
import { MonitorDetail } from './components/tools/monitor/MonitorDetail';
import { MonitorPvpDetail } from './components/tools/monitor/MonitorPvpDetail';
import { MonitorSales } from './components/tools/monitor/MonitorSales';
import { PvpMigration } from './components/tools/pvp-migration/PvpMigration';
import { Release } from './components/tools/release/Release';
import { ReleaseNote } from './components/tools/release/ReleaseNote';
import AppContextProvider from './contexts/app-context';

const container = document.getElementById('root')!;
const root = ReactDOMClient.createRoot(container);

const setToken = (userToken: any) => {
  if (userToken) {
    sessionStorage.setItem('token', userToken);
    window.open('/', '_self');
  }
};

const getToken = (): string => {
  const tokenString = sessionStorage.getItem('token');
  return tokenString ?? '';
};

const PrivateWrapper = () => {
  const token = getToken();
  return token ? <Outlet /> : <Navigate to="/login" />;
};

root.render(
  <BrowserRouter>
    <AppContextProvider>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route element={<PrivateWrapper />}>
            <Route path="/" element={<App />} />
            <Route path="/tools" element={<Tools />} />
            <Route path="/tools/monitor" element={<Monitor />} />
            <Route path="/tools/monitor/:id" element={<MonitorDetail />} />
            <Route path="/tools/monitor/:id/:page" element={<MonitorDetail />} />
            <Route path="/tools/monitor-pvp/:id" element={<MonitorPvpDetail />} />
            <Route path="/tools/monitor-sales/:id" element={<MonitorSales />} />
            <Route path="/tools/pvp-migration" element={<PvpMigration />} />
            <Route path="/tools/sales" element={<MonitorSales all />} />
            <Route path="/tools/configuration" element={<Configuration />} />
            <Route path="/tools/configuration/:id" element={<ConfigurationDetail />} />
            <Route path="/tools/release" element={<Release />} />
            <Route path="/tools/release/:id" element={<ReleaseNote />} />
          </Route>
        </Routes>
        <Toast />
      </div>
    </AppContextProvider>
  </BrowserRouter>,
);
