import React, { FC } from "react";

type NavbarItem = {
  id: string;
  label: string;
};

export const NavbarDropdown: FC<NavbarItem> = ({ id, label, children }) => {
  return (
    <>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          id={id}
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          href="#"
        >
          {label}
        </a>
        <ul className="dropdown-menu" aria-labelledby={id}>
          {children}
        </ul>
      </li>
    </>
  );
};

