import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { usePapaParse } from 'react-papaparse';

import { Page } from '../../Page';
import { PubReqList } from './pubReq-list';

// Create PubReq component
export const PvpMigration = () => {
  // Prepare states
  const [pvpId, setPvpId] = useState('');
  const [url, setUrl] = useState('');
  const [xml, setXml] = useState('');
  const [source, setSource] = useState('');
  const [pubReqs, setPubReqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [conf, setConf] = useState('');

  const { readString } = usePapaParse();

  // Fetch all pubReqs on initial render
  useEffect(() => {
    fetchPubReqs();
  }, []);

  // Fetch all pubReqs
  const fetchPubReqs = async () => {
    // Send GET request to 'pubReqs/all' endpoint
    axios
      .get(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/all`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        // Update the pubReqs state
        setPubReqs(response.data);

        // Update loading state
        setLoading(false);
      })
      .catch((error) => console.error(`There was an error retrieving the pubReq list: ${error}`));
  };

  // Reset all input fields
  const handleInputsReset = () => {
    setPvpId('');
    setUrl('');
    setXml('');
    setSource('');
  };

  // Create new pubReq
  const handlePubReqCreate = () => {
    // Send POST request to 'pubReqs/create' endpoint
    axios
      .post(
        `${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/create`,
        {
          pvpId: pvpId,
          url: url,
          xml: xml,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        // Fetch all pubReqs to refresh
        fetchPubReqs();
      })
      .catch((error) => console.error(`There was an error creating the ${pvpId} pubReq: ${error}`));
  };

  function sleep(time: number, callback: () => void) {
    var stop = new Date().getTime();
    while (new Date().getTime() < stop + time) {}
    callback();
  }

  // Create new pubReq
  const handlePubReqImport = () => {
    // TODO: TODO
    readString(source, {
      worker: true,
      complete: (results) => {
        const data = results.data;
        for (let i = 0; i < data.length; i++) {
          const pvpId = parseInt((data[i] as Array<any>)[0]);
          //const url = (data[i] as Array<any>)[1]; // REMOVE COMMENTS?
          if (pvpId > 0) {
            //if (url.length > 0) {
            //parseDetailPage(pvpId, url ?? "");
            //} else {
            axios
              .get(
                `${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/currentSearchPage/${pvpId}`,
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                  },
                },
              )
              .then((res) => {
                const searchPage = Object.values(res.data).join('');
                processData(pvpId, searchPage);
              })
              .catch((error) =>
                console.error(`There was an error creating the ${pvpId} pubReq: ${error}`),
              );
            //}
          }
        }
      },
    });
  };

  const processData = (pvpId: number, searchPage: string) => {
    parseSearchPage(pvpId, searchPage);
  };

  const loadXMLDoc = (filename: string) => {
    let xhttp = new XMLHttpRequest();
    xhttp.open('GET', filename, false);
    xhttp.send('');
    const parser = new DOMParser();
    let xmlDoc = parser.parseFromString(xhttp.responseText, 'text/xml');
    const errorNode = xmlDoc.querySelector('parsererror');
    if (errorNode) {
      console.log(filename);
      console.log('xhttp', xhttp);
      console.log('xmlDoc', xmlDoc);
      console.log('errorNode', errorNode);
    }
    return xmlDoc;
  };

  const parseSearchPage = (pvpId: number, xml: string) => {
    sleep(1000, () => {
      // executes after one second, and blocks the thread
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, 'text/xml');
      const xsl = loadXMLDoc('/_transformation-cerca.xslt');
      const xsltProcessor = new XSLTProcessor();
      xsltProcessor.importStylesheet(xsl);
      const resultDocument = xsltProcessor.transformToFragment(xmlDoc, document);
      parseDetailPage(pvpId, resultDocument.textContent ?? '');
    });
  };

  const parseDetailPage = (pvpId: number, url: string) => {
    sleep(1000, () => {
      // executes after one second, and blocks the thread
      console.log(
        'trying',
        `${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/currentDetailPage/${pvpId}/${btoa(
          url,
        ).replace('/', ':::')}`,
      );
      axios
        .get(
          `${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/currentDetailPage/${pvpId}/${btoa(
            url,
          ).replace('/', ':::')}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        )
        .then((res) => {
          const detailPage = Object.values(res.data)
            .join('')
            .replace(
              '<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="c9110c7b-7236-430f-808d-0a24965a9f0a" type="text/javascript" async></script>',
              '',
            )
            .replace(/<br>/g, '<br/>');
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(detailPage, 'text/xml');
          const xsl = loadXMLDoc('/_transformation-dettaglio.xslt');
          const xsltProcessor = new XSLTProcessor();
          xsltProcessor.importStylesheet(xsl);
          const resultDocument = xsltProcessor.transformToFragment(xmlDoc, document);
          const serializer = new XMLSerializer();
          const xmlString = serializer.serializeToString(resultDocument);
          axios
            .post(
              `${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/create`,
              {
                pvpId: pvpId,
                url: url,
                xml: xmlString,
              },
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
              },
            )
            .then((res) => {
              // Fetch all pubReqs to refresh
              // the pubReqs on the pubReqshelf list
              fetchPubReqs();
            })
            .catch((error) =>
              console.error(`There was an error creating the ${pvpId} pubReq: ${error}`),
            );
        })
        .catch((error) =>
          console.error(`There was an error creating the ${pvpId} pubReq: ${error}`),
        );
    });
  };

  // Submit new pubReq
  const handlePubReqSubmit = () => {
    // Check if all fields are filled
    if (pvpId.length > 0 && url.length > 0 && xml.length > 0) {
      // Create new pubReq
      handlePubReqCreate();

      console.info(`PubReq ${pvpId} added.`);

      // Reset all input fields
      handleInputsReset();
    }
  };

  // Import new pubReqs
  const handlePubReqImportSubmit = () => {
    if (source.length > 0) {
      // Import new pubReqs
      handlePubReqImport();

      console.info(`PubReqs imported.`);

      // Reset all input fields
      handleInputsReset();
    }
  };

  // Remove pubReq
  const handlePubReqRemove = (id: number, pvpId: string) => {
    // Send PUT request to 'pubReqs/delete' endpoint
    axios
      .put(
        `${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/delete`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      )
      .then(() => {
        console.log(`PubReq ${pvpId} removed.`);

        // Fetch all pubReqs to refresh
        // the pubReqs on the pubReqshelf list
        fetchPubReqs();
      })
      .catch((error) => console.error(`There was an error removing the ${pvpId} pubReq: ${error}`));
  };

  // Reset pubReq list (remove all pubReqs)
  const handleListReset = () => {
    // Send PUT request to 'pubReqs/reset' endpoint
    axios
      .put(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/reset`, undefined, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then(() => {
        // Fetch all pubReqs to refresh
        // the pubReqs on the pubReqshelf list
        fetchPubReqs();
      })
      .catch((error) => console.error(`There was an error resetting the pubReq list: ${error}`));
  };

  const handleListUpload = () => {
    // Send ALL publication requests to jugaad's SOAP API
    axios
      .post(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/upload-all`, undefined, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then(() => {
        // Fetch all pubReqs to refresh
        // the pubReqs on the pubReqshelf list
        fetchPubReqs();
      })
      .catch((error) => console.error(`There was an error uploading the pubReq list: ${error}`));
  };

  const handleCreateNginxConf = () => {
    // Create the conf file for NGINX
    axios
      .get(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/pubReqs/create-nginx-conf`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setConf(res.data);
        // Fetch all pubReqs to refresh
        // the pubReqs on the pubReqshelf list
        fetchPubReqs();
      })
      .catch((error) => console.error(`There was an error uploading the pubReq list: ${error}`));
  };

  return (
    <>
      <Page>
        <div className="card my-4">
          <div className="card-header">
            <a
              className="text-dark text-decoration-none"
              data-bs-toggle="collapse"
              href="#addPublicationRequestBody"
              role="button"
              aria-expanded="false"
              aria-controls="addPublicationRequestBody"
            >
              Add publication request
            </a>
          </div>
          <div className="card-body collapse" id="addPublicationRequestBody">
            <div className="row mb-2">
              <div className="col">
                <label className="form-label" htmlFor="pvpId">
                  PVP ID
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="pvpId"
                  name="pvpId"
                  value={pvpId}
                  onChange={(e) => setPvpId(e.currentTarget.value)}
                />
              </div>
              <div className="col">
                <label className="form-label" htmlFor="url">
                  URL
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="url"
                  name="url"
                  value={url}
                  onChange={(e) => setUrl(e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <label className="form-label" htmlFor="xml">
                  XML
                </label>
                <textarea
                  className="form-control"
                  id="xml"
                  name="xml"
                  value={xml}
                  onChange={(e) => setXml(e.currentTarget.value)}
                  style={{ height: '320px' }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <button onClick={handlePubReqSubmit} className="btn btn-primary btn-sm">
                  ADD THE PUB REQ
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card my-4">
          <div className="card-header">
            <a
              className="text-dark text-decoration-none"
              data-bs-toggle="collapse"
              href="#importPublicationRequestBody"
              role="button"
              aria-expanded="false"
              aria-controls="importPublicationRequestBody"
            >
              Import publication requests
            </a>
          </div>
          <div className="card-body collapse" id="importPublicationRequestBody">
            <div className="row mb-2">
              <div className="col">
                <label className="form-label" htmlFor="source">
                  CSV
                </label>
                <textarea
                  className="form-control"
                  id="source"
                  name="source"
                  value={source}
                  onChange={(e) => setSource(e.currentTarget.value)}
                  style={{ height: '320px' }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <button onClick={handlePubReqImportSubmit} className="btn btn-primary btn-sm">
                  IMPORT THE PUB REQS
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card my-4">
          <div className="card-header">Publication requests</div>
          <div className="card-body">
            <PubReqList
              pubReqs={pubReqs}
              loading={loading}
              handlePubReqRemove={handlePubReqRemove}
            />
          </div>
          {pubReqs.length > 0 && (
            <div className="card-footer">
              <button className="btn btn-danger" onClick={handleListReset}>
                ⚠️ DELETE ALL PUB REQS ⚠️
              </button>
              <button className="btn btn-primary ms-2" onClick={handleListUpload}>
                UPLOAD ALL PUB REQS
              </button>
              <button
                className="btn btn-primary ms-2"
                onClick={handleCreateNginxConf}
                data-bs-toggle="modal"
                data-bs-target="#confModal"
              >
                CREATE NGINX CONF
              </button>
            </div>
          )}
        </div>

        {conf && conf.length > 0 && (
          <div className="card my-4">
            <div className="card-header">CONF</div>
            <div className="card-body">{conf}</div>
          </div>
        )}

        <div className="modal" id="confModal" tabIndex={-1}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Da copiare in /var/www/jugaad-app--master/link/redirect-pvp-migration.txt
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {conf && conf.length > 0 ? <pre>{conf}</pre> : 'Loading...'}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => navigator.clipboard.writeText(conf)}
                  data-bs-dismiss="modal"
                >
                  COPY
                </button>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

