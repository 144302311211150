import React, { useContext, useState } from 'react';
import { ReleaseNoteContext } from '../../../contexts/release-note-context';
import { ReleaseNotesListRow } from './ReleaseNotesListRow';

export const ReleaseNotesList: React.FC<any> = () => {
  const { releaseNotesList, loading } = useContext(ReleaseNoteContext);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="table-responsive">
      <table className="table table-sm table-striped table-hover">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col">DESCRIPTION</th>
          </tr>
        </thead>
        <tbody>
          {releaseNotesList.releaseNotes.length > 0 ? (
            releaseNotesList.releaseNotes.map((rn, idx) => (
              <ReleaseNotesListRow key={rn.id} releaseNote={rn} position={idx + 1} />
            ))
          ) : (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={5}>
                There are no release notes to show. Create one!
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
