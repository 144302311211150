import React from "react";

import { Page } from "./components/Page";

function App() {
  return (
    <>
      <Page>
        <div className="container-fluid">Ciao!</div>
      </Page>
    </>
  );
}

export default App;

