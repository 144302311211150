import React from 'react';
import { Link } from 'react-router-dom';

import { NavbarDropdown } from './NavbarDropdown';
import { NavbarDropdownItem } from './NavbarDropdownItem';

export const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img
              src="/logo192.png"
              alt=""
              width="24"
              height="24"
              className="d-inline-block align-text-top me-2"
            />
            <span className="">Cockpit</span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#menu"
            aria-controls="menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="menu">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/*<NavbarItem url={"/test"} label={"ciaooo"} />*/}

              <NavbarDropdown id="toolsDropdown" label="TOOLS">
                <NavbarDropdownItem url="/tools/configuration" label="CONFIGURATIONS" />
                <NavbarDropdownItem url="/tools/monitor" label="MONITORS" />
                <NavbarDropdownItem url="/tools/release" label="RELEASES" />
                <NavbarDropdownItem url="/tools/sales" label="SALES" />
                {/* <NavbarDropdownItem url="/tools" label="TOOLS" /> */}
                {/* <NavbarDropdownItem url="/tools/pvp-migration" label="PVP MIGRATION" /> */}
              </NavbarDropdown>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

