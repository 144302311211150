// Import deps
import React from "react";

// Create interfaces
interface PubReqListRowUI {
  position: number;
  pubReq: {
    id: number;
    pvpId: string;
    url: string;
    xml: string;
  };
  handlePubReqRemove: (id: number, pvpId: string) => void;
}

// Create PubReqListRow component
export const PubReqListRow = (props: PubReqListRowUI) => (
  <tr>
    <th scope="row" className="text-nowrap">
      {props.position}
    </th>
    <td className="text-nowrap">{props.pubReq.pvpId}</td>
    <td className="text-nowrap">{props.pubReq.url}</td>
    <td className="text-nowrap">
      {new TextEncoder().encode(props.pubReq.xml).length}
    </td>
    <td>
      <button
        className="btn btn-danger text-nowrap"
        onClick={() =>
          props.handlePubReqRemove(props.pubReq.id, props.pubReq.pvpId)
        }
      >
        ⚠️ DELETE ⚠️
      </button>
    </td>
  </tr>
);

