import React from 'react';

// Create Monitor component
export const ConfigurationTable = (props: any) => {
  return (
    <>
      {props.data && props.data.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Key</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.key}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>NO DATA</div>
      )}
    </>
  );
};

