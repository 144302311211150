import React from "react";

import { Page } from "../Page";

export const Tools = () => {
  return (
    <>
      <Page>
        <div className="container-fluid">Tools</div>
      </Page>
    </>
  );
};

