import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReleaseNotesList } from '../types/release';
import { AppContext } from './app-context';

type ReleaseNoteContext = {
  releaseNotesList: ReleaseNotesList;
  loading: boolean;
  handleReleaseNoteCreate: (description: string) => void;
  handleReleaseNoteDelete: (releaseNoted: number) => void;
  handleReleaseNoteUpdate: (releaseNoteId: number, description: string) => Promise<void>;
};

export const ReleaseNoteContext = createContext<ReleaseNoteContext>({
  releaseNotesList: {
    count: 0,
    page: 1,
    pages: 1,
    releaseNotes: [],
  },
  loading: true,
  handleReleaseNoteCreate: () => {},
  handleReleaseNoteDelete: () => {},
  handleReleaseNoteUpdate: async () => {},
});

interface ReleaseNoteContextProviderProps {
  children?: React.ReactNode;
}

const ReleaseNoteContextProvider: React.FC<ReleaseNoteContextProviderProps> =
  React.memo<ReleaseNoteContextProviderProps>(({ children }) => {
    const { showToast } = React.useContext(AppContext);

    let { id: releaseId } = useParams();

    const [loading, setLoading] = useState(true);
    const [releaseNotesList, setReleaseNotesList] = useState<ReleaseNotesList>({
      count: 0,
      page: 1,
      pages: 1,
      releaseNotes: [],
    });

    // Fetch monitor
    const fetchReleaseNotes = async () => {
      // Send GET request to 'monitors/:id' endpoint
      axios
        .get(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/releases/${releaseId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          setReleaseNotesList(response.data);
          setLoading(false);
        })
        .catch((error) =>
          console.error(`There was an error retrieving the releases list: ${error}`),
        );
    };

    // Initial render
    useEffect(() => {
      fetchReleaseNotes();
    }, []);

    // Create new release note
    const handleReleaseNoteCreate = (description: string) => {
      // Send POST request to 'releases/notes/create' endpoint
      axios
        .post(
          `${process.env.REACT_APP_COCKPIT_SERVER_URL}/releases/notes/create`,
          {
            releaseId,
            description,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        )
        .then(() => {
          showToast('Success!', 'Release note created successfully!', 'success');
          fetchReleaseNotes();
        })
        .catch((error) => {
          showToast('Error!', 'There was an error creating the release note!', 'danger');
          throw `There was an error creating the release note: ${error}`;
        });
    };

    // Create new release
    const handleReleaseNoteDelete = (releaseNoteId: number) => {
      axios
        .delete(
          `${process.env.REACT_APP_COCKPIT_SERVER_URL}/releases/${releaseId}/notes/${releaseNoteId}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        )
        .then(() => {
          fetchReleaseNotes();
          showToast('Success!', 'Release note deleted successfully!', 'success');
        })
        .catch((error) => {
          showToast('Error!', 'There was an error deleting the release note!', 'danger');
          throw `There was an error deleting the release ${releaseId}: ${error}`;
        });
    };

    // Create new release
    const handleReleaseNoteUpdate = async (releaseNoteId: number, description: string) => {
      axios
        .put(
          `${process.env.REACT_APP_COCKPIT_SERVER_URL}/releases/${releaseId}/notes/${releaseNoteId}`,
          {
            description,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        )
        .then(() => {
          showToast('Success!', 'Release note updated successfully!', 'success');
        })
        .catch((error) => {
          showToast('Error!', 'There was an error updating the release note!', 'danger');
          throw `There was an error updating the release note ${releaseNoteId}: ${error}`;
        });
    };

    return (
      <ReleaseNoteContext.Provider
        value={{
          releaseNotesList,
          loading,
          handleReleaseNoteCreate,
          handleReleaseNoteDelete,
          handleReleaseNoteUpdate,
        }}
      >
        {children}
      </ReleaseNoteContext.Provider>
    );
  });

export default ReleaseNoteContextProvider;
