import React from 'react';
import ReactBootstrapToast, { ToastProps } from 'react-bootstrap/Toast';
import ReactBootstrapToastContainer from 'react-bootstrap/ToastContainer';
import { AppContext } from '../../contexts/app-context';

export const Toast: React.FC<ToastProps> = () => {
  const { toast, hideToast } = React.useContext(AppContext);

  return (
    <ReactBootstrapToastContainer position="top-end" className="position-fixed p-3">
      <ReactBootstrapToast show={toast.show} bg={toast.variant} onClose={hideToast} autohide>
        <ReactBootstrapToast.Header>
          <strong className="me-auto text-dark">{toast.title}</strong>
        </ReactBootstrapToast.Header>
        <ReactBootstrapToast.Body>
          <p className="text-light">{toast.message}</p>
        </ReactBootstrapToast.Body>
      </ReactBootstrapToast>
    </ReactBootstrapToastContainer>
  );
};
