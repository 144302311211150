import React, { useState } from 'react';
import axios from 'axios';

import { Page } from '../../Page';
import { ConfigurationTable } from './ConfigurationTable';
import ReactJson from 'react-json-view';

// Create Monitor component
export const Configuration = () => {
  // Prepare states
  const [select, setSelect] = useState<string>('');
  const [textResult, setTextResult] = useState<any>();
  const [format, setFormat] = useState<'json' | 'table'>('table');

  const handleSelectSubmit = async () => {
    let url = await getSelectUrl();
    if (!url) return;
    axios
      .get(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/configurations/${url}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        setTextResult(response.data);
      })
      .catch((error) => console.error(`There was an error: ${error}`));
  };

  const getSelectUrl = async () => {
    switch (select) {
      case 'env_chatbot': {
        return 'chatbot/env';
      }
      case 'env_cdn': {
        return 'cdn/env';
      }
      case 'env_vtour': {
        return 'vtour/env';
      }
      case 'vtour_disk_usage': {
        return 'vtour/disk-usage';
      }
      default:
        return '';
    }
  };

  return (
    <>
      <Page>
        <div className="card my-4">
          <div className="card-header">Configurations</div>
          <div className="card-body">
            <form>
              <label htmlFor="monitorConfiguration" className="form-label">
                Select the configuration
              </label>
              <select
                id="monitorConfiguration"
                className="form-select"
                value={select}
                onChange={(e) => setSelect(e.target.value)}
              >
                <option value=""></option>
                <option value="env_chatbot">ENV CHATBOT</option>
                <option value="env_cdn">ENV CDN</option>
                <option value="env_vtour">ENV VTOUR</option>
                <option value="vtour_disk_usage">VTOUR DISK USAGE</option>
              </select>
              <input
                type="button"
                value="Load"
                onClick={handleSelectSubmit}
                className="form-control btn btn-primary mt-3"
              />
            </form>
            {textResult && (
              <>
                <div className="btn-group mt-4" role="group" aria-label="Format">
                  <input
                    type="radio"
                    className="btn-check"
                    name="formatButtonRadio"
                    id="formatButtonRadioTable"
                    autoComplete="off"
                    defaultChecked={format === 'table'}
                    onClick={() => setFormat('table')}
                  />
                  <label className="btn btn-outline-primary" htmlFor="formatButtonRadioTable">
                    Table
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="formatButtonRadio"
                    id="formatButtonRadioJSON"
                    autoComplete="off"
                    defaultChecked={format === 'json'}
                    onClick={() => setFormat('json')}
                  />
                  <label className="btn btn-outline-primary" htmlFor="formatButtonRadioJSON">
                    JSON
                  </label>
                </div>
                <div className="mt-3">
                  {format === 'table' ? (
                    <ConfigurationTable data={textResult} />
                  ) : (
                    <ReactJson src={textResult} />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="card-footer"></div>
        </div>
      </Page>
    </>
  );
};

