import React, { FC } from "react";
import { Link } from "react-router-dom";

type NavbarItem = {
  url: string;
  label: string;
};

export const NavbarDropdownItem: FC<NavbarItem> = ({ url, label }) => {
  return (
    <>
      <li>
        <Link to={url} className="dropdown-item">
          {label}
        </Link>
      </li>
    </>
  );
};

