import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Page } from '../../Page';
import { MonitorsList } from './MonitorsList';

// THIS COULD BE MOVED TO DB
const apps = [
  {
    name: 'api',
    url: 'api.',
  },
  {
    name: 'soap',
    url: 'soap.api.',
  },
  {
    name: 'fe',
    url: '§',
  },
  {
    name: 'be',
    url: 'admin.',
  },
  {
    name: 'cms',
    url: 'cms.',
  },
];

// Create Monitor component
export const Monitor = () => {
  // Prepare states
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [monitors, setMonitors] = useState([]);
  const [loading, setLoading] = useState(true);

  // Initial render
  useEffect(() => {
    fetchMonitors();
  }, []);

  // Fetch all monitors
  const fetchMonitors = async () => {
    // Send GET request to 'monitors/all' endpoint
    axios
      .get(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/monitors/all`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        // Update the monitor state
        setMonitors(response.data);

        // Update loading state
        setLoading(false);
      })
      .catch((error) => console.error(`There was an error retrieving the monitors list: ${error}`));
  };

  // Reset all input fields
  const handleInputsReset = () => {
    setName('');
    setUrl('');
  };

  // Create new monitor
  const handleMonitorCreate = () => {
    // Send POST request to 'monitors/create' endpoint
    axios
      .post(
        `${process.env.REACT_APP_COCKPIT_SERVER_URL}/monitors/create`,
        {
          name: name,
          url: url,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      )
      .then((res) => {
        // Fetch all monitors to refresh
        fetchMonitors();
      })
      .catch((error) => console.error(`There was an error creating the ${name} monitor: ${error}`));
  };

  // Submit new monitor
  const handleMonitorSubmit = () => {
    // Check if all fields are filled
    if (name.length > 0 && url.length > 0) {
      // Create new monitor
      handleMonitorCreate();

      console.info(`Monitor ${name} added.`);

      // Reset all input fields
      handleInputsReset();
    }
  };

  return (
    <>
      <Page>
        <div className="card my-4">
          <div className="card-header">
            <a
              className="text-dark text-decoration-none"
              data-bs-toggle="collapse"
              href="#addMonitorBody"
              role="button"
              aria-expanded="false"
              aria-controls="addMonitorBody"
            >
              Add monitor
            </a>
          </div>
          <div className="card-body collapse" id="addMonitorBody">
            <div className="row mb-2">
              <div className="col">
                <label className="form-label" htmlFor="name">
                  NAME
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </div>
              <div className="col">
                <label className="form-label" htmlFor="url">
                  URL
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="url"
                  name="url"
                  value={url}
                  onChange={(e) => setUrl(e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <button onClick={handleMonitorSubmit} className="btn btn-primary btn-sm">
                  ADD MONITOR
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card my-4">
          <div className="card-header">Monitor</div>
          <div className="card-body">
            <MonitorsList monitors={monitors} loading={loading} />
          </div>
        </div>
      </Page>
    </>
  );
};

