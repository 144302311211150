import React, { useContext, useState } from 'react';
import { ReleaseNoteContext } from '../../../contexts/release-note-context';

export const CreateReleaseNote: React.FC<any> = () => {
  const { handleReleaseNoteCreate } = useContext(ReleaseNoteContext);

  const [description, setDescription] = useState('');

  // Reset all input fields
  const handleInputsReset = () => {
    setDescription('');
  };

  // Submit new release note
  const handleReleaseNoteSubmit = () => {
    // Check if all fields are filled
    if (description.length > 0) {
      // Create new release
      handleReleaseNoteCreate(description);

      // Reset all input fields
      handleInputsReset();
    }
  };

  return (
    <div className="card my-4">
      <div className="card-header">
        <a
          className="text-dark text-decoration-none"
          data-bs-toggle="collapse"
          href="#addReleaseNoteBody"
          role="button"
          aria-expanded="false"
          aria-controls="addReleaseNoteBody"
        >
          Add release note
        </a>
      </div>
      <div className="card-body collapse" id="addReleaseNoteBody">
        <div className="row mb-2">
          <div className="col">
            {/* TODO: TEXT AREA */}
            <label className="form-label" htmlFor="description">
              DESCRIPTION
            </label>
            <textarea
              className="form-control"
              id="description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.currentTarget.value)}
              rows={3}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <button onClick={handleReleaseNoteSubmit} className="btn btn-primary btn-sm">
              ADD RELEASE NOTE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
