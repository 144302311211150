import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactJson from 'react-json-view';
import axios from 'axios';

import { Page } from '../../Page';
import { padString } from '../../../utils/utils';

// Create Monitor component
export const MonitorPvpDetail = () => {
  let { id } = useParams();

  // Prepare states
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();
  const [columns, setColumns] = useState<any[]>([]);
  const [statusCard, setStatusCard] = useState(<></>);

  // Initial render
  useEffect(() => {
    fetchMonitor();
    const interval = setInterval(() => {
      fetchMonitor();
    }, 3600000);
    return () => clearInterval(interval);
  }, []);

  const fetchDetailMonitor = async (monitorPVPStatus: any) => {
    let statuses = {};
    for (let i = 0; i < monitorPVPStatus.pvpIds.length; i++) {
      await axios
        .get(
          `${process.env.REACT_APP_COCKPIT_SERVER_URL}/monitors/${id}/pvp/${monitorPVPStatus.pvpIds[i]}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        )
        .then((response) => {
          Object.assign(statuses, {
            [monitorPVPStatus.pvpIds[i]]: response.data,
          });
        })
        .catch((error) =>
          console.error(`There was an error retrieving the monitors list: ${error}`),
        );
    }
    return statuses;
  };

  // Fetch monitor
  const fetchMonitor = async () => {
    // Send GET request to 'monitors/:id' endpoint
    axios
      .get(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/monitors/${id}/pvp`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        let monitorPVPStatus = response.data;

        fetchDetailMonitor(monitorPVPStatus).then((monitorPVPDetailStatus) => {
          monitorPVPStatus.statuses = monitorPVPDetailStatus;

          // PARSE DATA
          const newColumns: any[] = [];
          const series = Object.keys(monitorPVPStatus.statuses);
          for (let i = 0; i < series.length; i++) {
            for (let j = 0; j < monitorPVPStatus.statuses[series[i]].length; j++) {
              if (
                newColumns.find(
                  (column) => column.uuid === monitorPVPStatus.statuses[series[i]][j].uuid,
                ) === undefined
              ) {
                const columnDate = new Date(monitorPVPStatus.statuses[series[i]][j].timestamp);
                newColumns.push({
                  uuid: monitorPVPStatus.statuses[series[i]][j].uuid,
                  label: `${padString(columnDate.getDate().toString())}/${padString(
                    (columnDate.getMonth() + 1).toString(),
                  )}`,
                });
              }
            }
          }
          setColumns(newColumns);
          setData(monitorPVPDetailStatus);

          // Update loading state
          setLoading(false);
        });
      })
      .catch((error) => console.error(`There was an error retrieving the monitors list: ${error}`));
  };

  return (
    <>
      <Page>
        <div className="card my-4">
          <div className="card-header">Monitor PVP</div>
          <div className="card-body p-0" style={{ overflowX: 'scroll' }}>
            {!loading && data && (
              <>
                <table className="table table-light" style={{ tableLayout: 'fixed' }}>
                  <thead className="thead-light">
                    <tr>
                      <th style={{ width: '20px' }}>&nbsp;</th>
                      {columns &&
                        columns.map((column: any, index: number) => {
                          return (
                            <th
                              key={`category-${index}`}
                              scope="col"
                              className="p-0"
                              style={{ width: '4px' }}
                            >
                              <div
                                style={{
                                  fontSize: '8px',
                                  marginBottom: '8px',
                                  marginLeft: '-7px',
                                  transform: 'rotate(-90deg)',
                                  width: '28px',
                                }}
                              >
                                {column.label}
                              </div>
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody className="table-hover">
                    {data &&
                      Object.keys(data).map((serie, serieIndex) => (
                        <tr key={`serie-${serieIndex}`}>
                          <th scope="row" style={{ textAlign: 'right' }}>
                            {serie.toUpperCase()}
                          </th>
                          {columns &&
                            columns.map((column, index) => {
                              const status =
                                data[serie][index]?.searchStatus + data[serie][index]?.detailStatus;
                              return (
                                <td
                                  key={`serie-${serie}-${index}`}
                                  className={`border border-light ${
                                    status === 2
                                      ? 'bg-success'
                                      : status === 1
                                      ? 'bg-warning'
                                      : status === 0
                                      ? 'bg-danger'
                                      : 'bg-light'
                                  }`}
                                  onClick={() => {
                                    if (status < 2) {
                                      setStatusCard(
                                        <>
                                          <div className="card my-4 text-white bg-danger">
                                            <div className="card-header">
                                              {serie.toUpperCase()}: KO AT{' '}
                                              {data[serie][index].errorContext}
                                            </div>
                                            <div className="card-body bg-light">
                                              <div className="card-text">
                                                <ReactJson
                                                  src={JSON.parse(
                                                    data[serie][index].errorDescription,
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>,
                                      );
                                    } else {
                                      setStatusCard(<></>);
                                    }
                                  }}
                                >
                                  &nbsp;
                                </td>
                              );
                            })}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
        {statusCard}
      </Page>
    </>
  );
};

