const padString = (string = '', padChar = '0', chars = 2) => {
  let _string = string.toString();
  while (_string.length < chars) {
    _string = `${padChar}${_string}`;
  }
  return _string;
};

const uuidNow = () => {
  const now = new Date();
  return `${now.getFullYear()}${padString(now.getMonth() + 1)}${padString(
    now.getDate(),
  )}${padString(now.getHours())}${padString(now.getMinutes())}00`;
};

module.exports = { padString, uuidNow };

