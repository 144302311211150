import React from 'react';
import ReleaseNoteContextProvider from '../../../contexts/release-note-context';
import { Page } from '../../Page';
import { CreateReleaseNote } from './CreateReleaseNote';
import { ReleaseNotesList } from './ReleaseNotesList';

export const ReleaseNote = () => {
  return (
    <>
      <Page>
        <ReleaseNoteContextProvider>
          <CreateReleaseNote />
          <div className="card my-4">
            <div className="card-header">Release</div>
            <div className="card-body">
              <ReleaseNotesList />
            </div>
          </div>
        </ReleaseNoteContextProvider>
      </Page>
    </>
  );
};
