import React from 'react';

import { MonitorsListRow } from './MonitorsListRow';

interface MonitorUI {
  id: number;
  name: string;
  url: string;
}

interface MonitorsListUI {
  monitors: MonitorUI[];
  loading: boolean;
}

// Create PubReqList component
export const MonitorsList = (props: MonitorsListUI) => {
  // Show loading message
  if (props.loading) return <p>Monitors table is loading...</p>;

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col">NAME</th>
            <th scope="col">URL</th>
            <th scope="col">STATUS</th>
          </tr>
        </thead>
        <tbody>
          {props.monitors.length > 0 ? (
            props.monitors.map((monitor: MonitorUI, idx) => (
              <MonitorsListRow key={monitor.id} monitor={monitor} position={idx + 1} />
            ))
          ) : (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={5}>
                There are no monitors to show. Create one!
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

