import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import it from 'date-fns/locale/it';

interface DateInputUI {
  id: string;
  name: string;
  selected: Date;
  onChange: (date: Date) => void;
  className?: string;
  disabled?: boolean;
  dateFormat?: string;
}

export const DateInput: React.FC<DateInputUI> = (props) => {
  // TODO: can we register the locale only once at the app level?
  // TODO: can we import the css only once at the app level?
  registerLocale('it', it);

  return <DatePicker locale="it" dateFormat="dd/MM/yyyy" {...props} />;
};
