import React, { FC } from "react";

import { Navbar } from "./navbar/Navbar";

export const Page: FC = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="container-fluid mt-4">{children}</div>
    </>
  );
};

