import React, { useContext } from 'react';
import { ReleaseContext } from '../../../contexts/releases-context';
import { ReleasesListRow } from './ReleasesListRow';

export interface ReleaseUI {
  id: number;
  year: number;
  sprint: number;
  releaseDate: Date;
}

// Create PubReqList component
export const ReleasesList = () => {
  const { releases, loading } = useContext(ReleaseContext);

  // Show loading message
  if (loading) return <p>Releases table is loading...</p>;

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col">YEAR</th>
            <th scope="col">SPRINT</th>
            <th scope="col">RELEASE DATE</th>
          </tr>
        </thead>
        <tbody>
          {releases.length > 0 ? (
            releases.map((release, idx) => (
              <ReleasesListRow key={release.id} release={release} position={idx + 1} />
            ))
          ) : (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={5}>
                There are no releases to show. Create one!
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
