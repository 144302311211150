import React from "react";

import { PubReqListRow } from "./pubReq-list-row";

interface PubReqUI {
  id: number;
  pvpId: string;
  url: string;
  xml: string;
}

interface PubReqListUI {
  pubReqs: PubReqUI[];
  loading: boolean;
  handlePubReqRemove: (id: number, pvpId: string) => void;
}

// Create PubReqList component
export const PubReqList = (props: PubReqListUI) => {
  // Show loading message
  if (props.loading) return <p>Pubreqs table is loading...</p>;

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col">PVP ID</th>
            <th scope="col">URL</th>
            <th scope="col">XML (bytes)</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {props.pubReqs.length > 0 ? (
            props.pubReqs.map((pubReq: PubReqUI, idx) => (
              <PubReqListRow
                key={pubReq.id}
                pubReq={pubReq}
                position={idx + 1}
                handlePubReqRemove={props.handlePubReqRemove}
              />
            ))
          ) : (
            <tr>
              <td style={{ textAlign: "center" }} colSpan={5}>
                There are no pubReqs to show. Create one!
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

