import axios from 'axios';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useParams } from 'react-router-dom';
// @ts-ignore
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { padString } from '../../../utils/utils';
import { Page } from '../../Page';

type Event = {
  id: number;
  title: string | number;
  start: Date;
  end: Date;
  allDay?: boolean;
  saleMode?: string;
  procedureCode?: string;
  ivg?: string;
  monitorId: number;
};

const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

type Props = {
  all?: boolean;
};

// Create Monitor component
export const MonitorSales: FC<Props> = (props: Props) => {
  let { id } = useParams();

  // Prepare states
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState<string>('calendar');

  const [data, setData] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>();
  const [isShowModalOpen, setIsShowModalOpen] = useState<boolean>(false);
  const [currentEvent, setCurrentEvent] = useState<any>(null);

  // Initial render
  useEffect(() => {
    fetchMonitor();
  }, []);

  // Fetch monitor
  const fetchMonitor = async () => {
    axios
      .get(
        props.all
          ? `${process.env.REACT_APP_COCKPIT_SERVER_URL}/monitors/allSales`
          : `${process.env.REACT_APP_COCKPIT_SERVER_URL}/monitors/sales/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      )
      .then((response) => {
        let monitorSales = response.data;
        const events: Event[] = [];
        const data: Event[] = [];

        if (monitorSales && monitorSales.data) {
          for (let i = 0; i < monitorSales.data.length; i++) {
            const sale = monitorSales.data[i];
            const saleDate = new Date(sale.auctionStartAt);
            const prevDate = i > 0 ? events[events.length - 1].start : new Date();

            if (i > 0 && isSameDay(prevDate, saleDate)) {
              events[events.length - 1] = {
                id: sale.id,
                title: Number(events[events.length - 1].title) + 1,
                start: prevDate,
                end: prevDate,
                allDay: true,
                monitorId: sale.monitorId
              };
            } else {
              events.push({
                id: sale.id,
                title: 1,
                start: saleDate,
                end: saleDate,
                allDay: true,
                monitorId: sale.monitorId
              });
            }

            data.push({
              id: sale.id,
              title: sale.title,
              start: saleDate,
              end: saleDate,
              allDay: true,
              saleMode: sale.saleMode,
              procedureCode: sale.procedureCode,
              ivg: monitorSales?.monitorName ?? sale?.monitorName ?? '',
              monitorId: sale.monitorId
            });
          }
        }
        setEvents(events);
        setData(data);

        // Update loading state
        setLoading(false);
      })
      .catch((error) => console.error(`There was an error retrieving the monitors list: ${error}`));
  };

  const rowTable = (e: Event) => {
    return (
      <tr key={e.id} style={{backgroundColor: e.monitorId === 1 ? 'blue' : e.monitorId === 2 ? 'green' : e.monitorId === 3 ? 'orange' : e.monitorId === 4 ? 'red' : 'black'}}>
        <td style={{color: 'white'}}>{e.title}</td>
        <td style={{color: 'white'}}>
          {padString(new Date(e.start).getDate().toString()) +
            '/' +
            padString((new Date(e.start).getMonth() + 1).toString()) +
            ' ' +
            padString(new Date(e.start).getHours().toString()) +
            ':' +
            padString(new Date(e.start).getMinutes().toString())}
        </td>
        <td style={{color: 'white'}}>{e.saleMode}</td>
        <td style={{color: 'white'}}>{e.procedureCode}</td>
        <td style={{color: 'white'}}>{e.ivg}</td>
      </tr>
    );
  };
  return (
    <>
      <Page>
        <div className="card my-4">
          <div className="card-header">Monitor Sales</div>
          {!loading && (
            <>
              <form style={{ margin: '20px' }}>
                <label htmlFor="salesView" className="form-label">
                  View as:
                </label>
                <select
                  id="salesView"
                  className="form-select"
                  value={view}
                  onChange={(e) => {
                    setView(e.target.value);
                    setCurrentEvent(null);
                    setIsShowModalOpen(false);
                  }}
                >
                  <option value="calendar">calendar</option>
                  <option value="list">list</option>
                </select>
              </form>

              {view === 'calendar' && (
                <>
                  { props.all && <div style={{
                    backgroundColor: 'lightgray',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50px',
                    width: '100%',
                  }}>
                    <ul style={{ display: 'flex', flexDirection: 'row', listStyleType: 'circle'}}>
                      <li style={{marginLeft: '15px', color: 'blue', fontWeight: 'bold'}}>IVG-RE</li>
                      <li style={{marginLeft: '30px', color: 'green', fontWeight: 'bold'}}>IVG-CR</li>
                      <li style={{marginLeft: '30px', color: 'orange', fontWeight: 'bold'}}>IVG-PR</li>
                      <li style={{marginLeft: '30px', color: 'red', fontWeight: 'bold'}}>IVG-TR</li>
                      <li style={{marginLeft: '30px', color: 'black', fontWeight: 'bold'}}>IVG-RO</li>
                    </ul>  
                  </div>
                  }
                  
                  <Calendar
                    localizer={momentLocalizer(moment)}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    popup
                    eventPropGetter={(event: any) => ({
                      style: {
                        // IVGRE - 1  IVGCR - 2   IVGPR - 3   IVGTR - 4   IVGRO - 5
                        backgroundColor: event.monitorId === 1 ? 'blue' : event.monitorId === 2 ? 'green' : event.monitorId === 3 ? 'orange' : event.monitorId === 4 ? 'red' : 'black',
                      },
                    })}
                    onSelectEvent={(event: any) => {
                      if (event.title) {
                        setCurrentEvent(event);
                        setIsShowModalOpen(true);
                      } else {
                        setCurrentEvent(null);
                      }
                    }}
                    views={['month', 'week', 'day']}
                    defaultView={'month'}
                    selectable={true}
                    style={{ height: '500px', margin: '20px' }}
                  />
                  {isShowModalOpen && (
                    <div className="modal-body">
                      <button
                        className="btn btn-outline-success btn-sm text-nowrap mx-1"
                        onClick={() => setIsShowModalOpen(false)}
                      >
                        Hide
                      </button>
                      <table className="table table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th>Lotto</th>
                            <th>Data</th>
                            <th>Tipo di vendita</th>
                            <th>Numero procedura</th>
                            <th>IVG</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentEvent &&
                            data
                              .filter((e) => isSameDay(e.start, currentEvent.start) && e.monitorId === currentEvent.monitorId)
                              .map((e) => {
                                return rowTable(e);
                              })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
              {view === 'list' && (
                <div className="modal-body">
                  <table className="table table-sm table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Lotto</th>
                        <th>Data</th>
                        <th>Tipo di vendita</th>
                        <th>Numero procedura</th>
                        <th>IVG</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((e) => {
                        return rowTable(e);
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </Page>
    </>
  );
};

