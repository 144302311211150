// Import deps
import React from 'react';
import { Link } from 'react-router-dom';

// Create interfaces
interface MonitorsListRowUI {
  position: number;
  monitor: {
    id: number;
    name: string;
    url: string;
  };
}

// Create PubReqListRow component
export const MonitorsListRow = (props: MonitorsListRowUI) => (
  <tr>
    <th scope="row" className="text-nowrap">
      {props.position}
    </th>
    <td className="text-nowrap">{props.monitor.name}</td>
    <td className="text-nowrap">{props.monitor.url}</td>
    <td>
      <Link to={`/tools/monitor/${props.monitor.id}`} className="btn btn-primary btn-sm mx-1">
        Detail
      </Link>
      <Link to={`/tools/monitor-pvp/${props.monitor.id}`} className="btn btn-primary btn-sm mx-1">
        PVP Detail
      </Link>
      <Link to={`/tools/configuration/${props.monitor.id}`} className="btn btn-primary btn-sm mx-1">
        Configurations
      </Link>
      <Link to={`/tools/monitor-sales/${props.monitor.id}`} className="btn btn-primary btn-sm mx-1">
        Sales
      </Link>
    </td>
  </tr>
);

