import React, { useContext, useState } from 'react';
import { ReleaseNote } from '../../../types/release';
import { ReleaseNoteContext } from '../../../contexts/release-note-context';

interface ReleaseNotesListRowUI {
  position: number;
  releaseNote: ReleaseNote;
}

export const ReleaseNotesListRow: React.FC<ReleaseNotesListRowUI> = ({ releaseNote, position }) => {
  const { handleReleaseNoteDelete, handleReleaseNoteUpdate } = useContext(ReleaseNoteContext);
  const [description, setDescription] = useState(releaseNote.description);

  // Reset all input fields
  const handleInputsReset = () => {
    setDescription(releaseNote.description);
  };

  const [editMode, setEditMode] = React.useState(false);
  const handleToggleEdit = () => {
    if (editMode) {
      setEditMode(false);
      handleInputsReset();
    } else {
      setEditMode(true);
    }
  };

  const handleSubmitReleaseNoteUpdate = async () => {
    try {
      await handleReleaseNoteUpdate(releaseNote.id, description);
      setEditMode(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <tr key={releaseNote.id}>
      <th scope="row" className="text-nowrap">
        {position}
      </th>
      <td className="text-nowrap">
        <textarea
          // className="form-control"
          id="description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          disabled={!editMode}
          rows={3}
          cols={80}
        />
      </td>
      <td>
        <button className="btn btn-warning btn-sm" onClick={handleToggleEdit}>
          {editMode ? 'Cancel' : 'Edit'}
        </button>
        {editMode && (
          <button className="btn btn-primary btn-sm ms-2" onClick={handleSubmitReleaseNoteUpdate}>
            Save
          </button>
        )}
      </td>
      <td>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleReleaseNoteDelete(releaseNote.id)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};
