// Import deps
import React from 'react';
import { Link } from 'react-router-dom';
import { Release } from '../../../types/release';
import { ReleaseContext } from '../../../contexts/releases-context';
import { DateInput } from '../../inputs';

// Create interfaces
interface ReleasesListRowUI {
  position: number;
  release: Release;
}

// Create PubReqListRow component
export const ReleasesListRow = (props: ReleasesListRowUI) => {
  const { handleReleaseDelete, handleReleaseUpdate } = React.useContext(ReleaseContext);

  const [year, setYear] = React.useState(props.release.year);
  const [sprint, setSprint] = React.useState(props.release.sprint);
  const [releaseDate, setReleaseDate] = React.useState(new Date(props.release.releaseDate));

  // Reset all input fields
  const handleInputsReset = () => {
    setYear(props.release.year);
    setSprint(props.release.sprint);
    setReleaseDate(new Date(props.release.releaseDate));
  };

  const [editMode, setEditMode] = React.useState(false);
  const handleToggleEdit = () => {
    if (editMode) {
      setEditMode(false);
      handleInputsReset();
    } else {
      setEditMode(true);
    }
  };

  const handleSubmitReleaseUpdate = async () => {
    try {
      await handleReleaseUpdate(props.release.id, year, sprint, releaseDate);
      setEditMode(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <tr>
      <th scope="row" className="text-nowrap">
        {props.position}
      </th>
      <td className="text-nowrap">
        <input
          // className="form-control"
          type="text"
          id="year"
          name="year"
          value={year}
          onChange={(e) => setYear(parseInt(e.currentTarget.value))}
          disabled={!editMode}
        />
      </td>
      <td className="text-nowrap">
        <input
          // className="form-control"
          type="text"
          id="sprint"
          name="sprint"
          value={sprint}
          onChange={(e) => setSprint(parseInt(e.currentTarget.value))}
          disabled={!editMode}
        />
      </td>
      <td className="text-nowrap">
        <DateInput
          //className="form-control"
          id="releaseDate"
          name="releaseDate"
          selected={releaseDate}
          onChange={(date: Date) => setReleaseDate(date)}
          disabled={!editMode}
        />
      </td>
      <td>
        <Link to={`/tools/release/${props.release.id}`} className="btn btn-primary btn-sm mx-1">
          Detail
        </Link>
      </td>
      <td>
        <button className="btn btn-warning btn-sm" onClick={handleToggleEdit}>
          {editMode ? 'Cancel' : 'Edit'}
        </button>
        {editMode && (
          <button className="btn btn-primary btn-sm ms-2" onClick={handleSubmitReleaseUpdate}>
            Save
          </button>
        )}
      </td>
      <td>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleReleaseDelete(props.release.id)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};
