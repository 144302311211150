import React from 'react';
import ReleaseContextProvider from '../../../contexts/releases-context';
import { Page } from '../../Page';
import { CreateRelease } from './CreateRelease';
import { ReleasesList } from './ReleasesList';
import { Toast } from '../../common';

// Create Release component
export const Release = () => {
  return (
    <Page>
      <ReleaseContextProvider>
        <CreateRelease />
        <div className="card my-4">
          <div className="card-header">Release</div>
          <div className="card-body">
            <ReleasesList />
          </div>
        </div>
      </ReleaseContextProvider>
      <Toast />
    </Page>
  );
};
