import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Page } from '../../Page';
import MonitorDetailTable from './MonitorDetailTable';

export const MonitorDetail = () => {
  const navigate = useNavigate();

  let { id, page = 1 } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();

  // Initial render
  useEffect(() => {
    fetchMonitor();
    const interval = setInterval(() => {
      fetchMonitor();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Reload data when page changes
  useEffect(() => {
    fetchMonitor();
  }, [page]);

  // Fetch monitor
  const fetchMonitor = async () => {
    // Send GET request to 'monitors/:id' endpoint
    axios
      .get(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/monitors/${id}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => console.error(`There was an error retrieving the monitors list: ${error}`));
  };

  return (
    <>
      <Page>
        <div className="card my-4">
          <div className="card-header">Monitor</div>
          <div className="card-body">
            {loading && <>Loading...</>}
            {!loading && data && (
              <>
                <div>
                  <MonitorDetailTable data={data} />
                </div>
              </>
            )}
          </div>
          <div className="card-footer">
            {!loading && data && (
              <>
                <div className="float-start">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={page == 1}
                    onClick={() => navigate(`/tools/monitor/${id}/${Number(page) - 1}`)}
                  >
                    &lt; Previous
                  </button>
                  <span className="mx-2 align-middle">
                    Page {page} of {data.pages}
                  </span>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={page == data.pages}
                    onClick={() => navigate(`/tools/monitor/${id}/${Number(page) + 1}`)}
                  >
                    Next &gt;
                  </button>
                </div>
                <div className="float-end">
                  <p className="mt-2 mb-2">Total results: {data.count}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </Page>
    </>
  );
};

