import React, { Dispatch, SetStateAction, useState } from 'react';
import axios from 'axios';

type Props = {
  setToken: Dispatch<SetStateAction<undefined>>;
};

export const Login = ({ setToken }: Props) => {
  const [username, setUserName] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/auth/login`, { username, password })
      .then((data) => {
        setToken(data.data.token);
      })
      .catch((error) => {
        console.error('handleSubmit', error.response.data.message);
        setError(error.response.data.message);
      });
  };

  return (
    <>
      <div className="container-fluid mt-5 d-flex flex-row justify-content-center">
        <div className="card my-4">
          <div className="card-header">Login</div>
          <div className="card-body" id="loginBody">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="username">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
            {error && (
              <div className="alert alert-danger mt-3" role="alert">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

