import React, { useState } from 'react';
import ReactJson from 'react-json-view';

import { padString } from '../../../utils/utils';

const MonitorDetailTable = ({ data }) => {
  const [errorDescription, setErrorDescription] = useState('');
  const { count, page, pages, results } = data;
  let timestamp = 0;

  return (
    <>
      <table className="table table-sm table-striped table-hover">
        <thead>
          <tr>
            <th>Data</th>
            <th>Servizio</th>
            <th>Stato</th>
          </tr>
        </thead>
        <tbody>
          {results.map((el) => {
            const differentTimestamp = timestamp !== el.timestamp;
            timestamp = el.timestamp;
            const errorDescription = JSON.parse(el.errorDescription);
            const errorShortDescriptionArray = [];
            if (errorDescription?.status) {
              errorShortDescriptionArray.push(errorDescription.status);
            }
            if (errorDescription?.message) {
              errorShortDescriptionArray.push(errorDescription.message);
            }
            const errorShortDescription = errorShortDescriptionArray.join(' - ');
            return (
              <tr
                className={`table-${el.status ? 'success' : 'danger'}`}
                key={el.id}
                style={{
                  borderTop: differentTimestamp ? '2px solid black' : '',
                }}
              >
                <td>
                  {padString(new Date(el.timestamp).getDate().toString()) +
                    '/' +
                    padString((new Date(el.timestamp).getMonth() + 1).toString()) +
                    ' ' +
                    padString(new Date(el.timestamp).getHours().toString()) +
                    ':' +
                    padString(new Date(el.timestamp).getMinutes().toString())}
                </td>
                <td>{el.app}</td>
                <td
                  onClick={() => {
                    el.status
                      ? setErrorDescription('')
                      : setErrorDescription(errorDescription ?? '');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {errorShortDescription}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {errorDescription && (
        <div className="mt-3">
          <ReactJson src={errorDescription} />
        </div>
      )}
    </>
  );
};

export default MonitorDetailTable;

