import React, { FC, useContext, useState } from 'react';
import { ReleaseContext } from '../../../contexts/releases-context';
import { DateInput } from '../../inputs';

export const CreateRelease: FC<any> = () => {
  const { handleReleaseCreate } = useContext(ReleaseContext);

  const [year, setYear] = useState(new Date().getFullYear());
  const [sprint, setSprint] = useState(0);
  const [releaseDate, setReleaseDate] = useState(new Date());

  // Reset all input fields
  const handleInputsReset = () => {
    setYear(new Date().getFullYear());
    setSprint(0);
    setReleaseDate(new Date());
  };

  // Submit new release
  const handleReleaseSubmit = () => {
    // Check if all fields are filled
    if (year > 0 && sprint > 0 && releaseDate) {
      // Create new release
      handleReleaseCreate(year, sprint, releaseDate);

      // Reset all input fields
      handleInputsReset();
    }
  };

  return (
    <>
      <div className="card my-4">
        <div className="card-header">
          <a
            className="text-dark text-decoration-none"
            data-bs-toggle="collapse"
            href="#addReleaseBody"
            role="button"
            aria-expanded="false"
            aria-controls="addReleaseBody"
          >
            Add release
          </a>
        </div>
        <div className="card-body collapse" id="addReleaseBody">
          <div className="row mb-2">
            <div className="col">
              <label className="form-label" htmlFor="year">
                YEAR
              </label>
              <input
                className="form-control"
                type="text"
                id="year"
                name="year"
                value={year}
                onChange={(e) => setYear(parseInt(e.currentTarget.value))}
              />
            </div>
            <div className="col">
              <label className="form-label" htmlFor="sprint">
                SPRINT
              </label>
              <input
                className="form-control"
                type="text"
                id="sprint"
                name="sprint"
                value={sprint}
                onChange={(e) => 
                  setSprint(e.currentTarget.value ? parseInt(e.currentTarget.value) : 0)}
              />
            </div>
            <div className="col">
              <label className="form-label d-block" htmlFor="releaseDate">
                RELEASE DATE
              </label>
              <DateInput
                className="form-control"
                id="releaseDate"
                name="releaseDate"
                selected={releaseDate}
                onChange={(date: Date) => setReleaseDate(date)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <button onClick={handleReleaseSubmit} className="btn btn-primary btn-sm">
                ADD RELEASE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
