import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { Release } from '../types/release';
import { AppContext } from './app-context';

type ReleaseContext = {
  releases: Release[];
  loading: boolean;
  handleReleaseCreate: (year: number, sprint: number, releaseDate: Date) => void;
  handleReleaseDelete: (releaseId: number) => void;
  handleReleaseUpdate: (
    releaseId: number,
    year: number,
    sprint: number,
    releaseDate: Date,
  ) => Promise<void>;
};

export const ReleaseContext = createContext<ReleaseContext>({
  releases: [],
  loading: true,
  handleReleaseCreate: () => {},
  handleReleaseDelete: () => {},
  handleReleaseUpdate: async () => {},
});

type ReleaseContextProviderProps = {
  children?: React.ReactNode;
  //   value: ReleaseContext;
};

const ReleaseContextProvider: React.FC<ReleaseContextProviderProps> =
  React.memo<ReleaseContextProviderProps>(({ children }) => {
    const { showToast } = React.useContext(AppContext);

    const [releases, setReleases] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch all releases
    const fetchReleases = async () => {
      // Send GET request to 'releases/all' endpoint
      axios
        .get(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/releases/all`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          // Update the release state
          setReleases(response.data);

          // Update loading state
          setLoading(false);
        })
        .catch((error) =>
          console.error(`There was an error retrieving the releases list: ${error}`),
        );
    };

    // Initial render
    useEffect(() => {
      fetchReleases();
    }, []);

    // Create new release
    const handleReleaseCreate = (year: number, sprint: number, releaseDate: Date) => {
      // Send POST request to 'releases/' endpoint
      axios
        .post(
          `${process.env.REACT_APP_COCKPIT_SERVER_URL}/releases`,
          {
            year,
            sprint,
            releaseDate,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        )
        .then(() => {
          fetchReleases();
          showToast('Success!', 'Release created successfully!', 'success');
        })
        .catch((error) => {
          showToast('Error!', 'There was an error creating the release!', 'danger');
          throw `There was an error creating the release ${year}.${sprint}: ${error}`;
        });
    };

    // Create new release
    const handleReleaseDelete = (releaseId: number) => {
      // Send DELETE request to 'releases/' endpoint
      axios
        .delete(`${process.env.REACT_APP_COCKPIT_SERVER_URL}/releases/${releaseId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
        .then(() => {
          fetchReleases();
          showToast('Success!', 'Release deleted successfully!', 'success');
        })
        .catch((error) => {
          showToast('Error!', 'There was an error deleting the release!', 'danger');
          throw `There was an error deleting the release ${releaseId}: ${error}`;
        });
    };

    // Create new release
    const handleReleaseUpdate = async (
      releaseId: number,
      year: number,
      sprint: number,
      releaseDate: Date,
    ) => {
      // Send PUT request to 'releases/' endpoint
      axios
        .put(
          `${process.env.REACT_APP_COCKPIT_SERVER_URL}/releases/${releaseId}`,
          {
            year,
            sprint,
            releaseDate,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        )
        .then(() => {
          showToast('Success!', 'Release updated successfully!', 'success');
        })
        .catch((error) => {
          showToast('Error!', 'There was an error updating the release!', 'danger');
          throw `There was an error deleting the release ${releaseId}: ${error}`;
        });
    };

    return (
      <ReleaseContext.Provider
        value={{
          releases,
          loading,
          handleReleaseCreate,
          handleReleaseDelete,
          handleReleaseUpdate,
        }}
      >
        {children}
      </ReleaseContext.Provider>
    );
  });

export default ReleaseContextProvider;
