import React from 'react';
import { createContext } from 'react';

type Toast = {
  title: string;
  message: string;
  show: boolean;
  variant: string;
};

type AppContext = {
  showToast: (title: string, message: string, variant?: string) => void;
  hideToast: () => void;
  toast: Toast;
};

export const AppContext = createContext<AppContext>({
  showToast: () => null,
  hideToast: () => null,
  toast: {
    title: '',
    message: '',
    show: false,
    variant: 'info',
  },
});

interface AppContextProviderProps {
  children?: React.ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = React.memo<AppContextProviderProps>(
  ({ children }) => {
    const [toast, setToast] = React.useState({
      title: 'Info',
      message: 'An info message.',
      show: false,
      variant: 'info',
    });

    const showToast = (title: string, message: string, variant = 'info') => {
      setToast((prevState) => ({
        ...prevState,
        title,
        message,
        variant,
        show: true,
      }));
    };

    const hideToast = () => {
      setToast((prevState) => ({
        ...prevState,
        show: false,
      }));
    };

    return (
      <AppContext.Provider
        value={{
          showToast,
          hideToast,
          toast,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  },
);

export default AppContextProvider;
